import { Contact } from "../contact/contact";
import { Account } from "../account/account";

export class User {
    user_id: string;
    account_id: string;
    contact_id: string;
    user_name: string;
    display_name: string;
    avatar_image_url: string;
    email_address: string;
    phone_number: string;
    mobile_number: string;
    created: Date;
    updated: Date;
    last_login_date: Date;
    password: string;
    
    permissions: string[];

    contact: Contact;
    account: Account;
}