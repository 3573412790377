import { Component, ViewEncapsulation, Injector } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap';

import { AppConfig } from '../app.config';

// import { SanitizeHtmlPipe } from '../pipes/sanitize-html.pipe';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmationModal.template.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./confirmationModal.style.scss']
})
export class ConfirmationModalComponent {
  confirmTitle: string;
  confirmMessage: string;
  btn_color: string;

  constructor(config: AppConfig,
    injector: Injector,
    public confirmationModal: BsModalRef,
    public modalService: BsModalService) {
      console.log("Confirmation Modal");
    }

    confirm(): void {
      this.modalService.setDismissReason('confirmed');
      this.confirmationModal.hide();
    }
   
    decline(): void {
      this.modalService.setDismissReason('canceled');
      this.confirmationModal.hide();
    }
}
