
export function fixDateTimeZone(dateValue) {
  if (dateValue) {
    if (typeof dateValue === 'string') {
      // convert to date object
      // date constructor will apply timezone deviations from UTC (i.e. if locale is behind UTC 'dt' will be one day behind)
      dateValue = new Date(dateValue);
    }
    // undo the timezone adjustment we did during the formatting
    dateValue.setMinutes(dateValue.getMinutes() - dateValue.getTimezoneOffset());
  }
}