// Shared Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfirmationModalComponent } from '../confirmation_modal/confirmationModal.component';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ConfirmationModalComponent,
  ],
  entryComponents: [
    ConfirmationModalComponent,
  ],
  exports: [
    ConfirmationModalComponent,
  ],
  providers: [
  ]
})
export class SharedModule { }